import React from 'react'
import {graphql} from 'gatsby'

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'

import SEO from '../components/seo'
import Layout from '../containers/layout'

import Hero from '../components/hero/Hero'
import HeroLogo from '../svg/hero_logo.svg'

export const query = graphql`
  query ThanksPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      heroQuoteCredit
      _rawHeroQuote

      mainImage {
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          _id
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      seperatorImage {
      asset {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    }


    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

  }
`

const ThanksPage = props => {
  const {data, errors, pageContext} = props

  const site = (data || {}).site
  const menu = (data || {}).menu
  const socialMedia = (data || {}).socialMedia

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage>
      {pageContext.intl.language === 'de'
        ? <SEO title={site.title.de} description={site.description} keywords={site.keywords} />
        : <SEO title={site.title.en} description={site.description} keywords={site.keywords} />
      }
      <div className='hero--wrapper thanks-page'>
        <Hero fluid={site.mainImage.asset.fluid} mainImage={site.mainImage} >
          <div className='herotext--wrapper' >
            <h1 className='h1 inline-grid--hero'>
              <div className='hero-logowrapper'>
                <HeroLogo />
              </div>
            </h1>

          </div>
        </Hero>
      </div>
      <Container>
        <h1 hidden>Welcome to {site.title.en}</h1>
        <>
          <div className='thanks-message'>
            <h1>Vielen Dank für deine Email.</h1>
            <p>Wir melden uns so schnell wie möglich bei dir zurück.</p>
          </div>

        </>
      </Container>
    </Layout>
  )
}

export default ThanksPage
